import { __decorate } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
let ShareInvite = class ShareInvite extends Vue {
    constructor() {
        super(...arguments);
        this.copyInviteLinkSuccessText = "";
    }
    get inviteLink() {
        if (process.env.SHORT_URL) {
            return `https://${process.env.SHORT_URL}/${this.$route.params.roomId}`;
        }
        return window.location.href.split('?')[0].toLowerCase();
    }
    copyInviteLink() {
        // @ts-expect-error $el actually does exist
        let textfield = this.$refs.inviteLinkText.$el.querySelector('input');
        if (!textfield) {
            console.error("failed to copy link: input not found");
            return;
        }
        textfield.select();
        document.execCommand("copy");
        this.copyInviteLinkSuccessText = "Copied!";
        setTimeout(() => {
            this.copyInviteLinkSuccessText = "";
            textfield?.blur();
        }, 3000);
    }
    onFocusHighlightText(e) {
        e.target.select();
    }
};
ShareInvite = __decorate([
    Component({
        name: "ShareInvite",
    })
], ShareInvite);
export default ShareInvite;
