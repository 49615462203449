import { __decorate } from "tslib";
import Vue from "vue";
import ProcessedText from "@/components/ProcessedText.vue";
import connection from "@/util/connection";
import Component from 'vue-class-component';
let Chat = class Chat extends Vue {
    constructor() {
        super(...arguments);
        this.inputValue = "";
        this.stickToBottom = true;
    }
    updated() {
        if (this.stickToBottom) {
            const div = this.$refs["messages"];
            div.scrollTop = div.scrollHeight;
        }
    }
    onInputKeyDown(e) {
        if (e.key === "Enter" && this.inputValue.trim() !== "") {
            connection.send({ action: "chat", text: this.inputValue });
            this.inputValue = "";
            this.stickToBottom = true;
        }
    }
    onScroll() {
        const div = this.$refs["messages"];
        const distToBottom = div.scrollHeight - div.clientHeight - div.scrollTop;
        this.stickToBottom = distToBottom === 0;
    }
};
Chat = __decorate([
    Component({
        name: "Chat",
        components: {
            ProcessedText,
        },
    })
], Chat);
export default Chat;
